<template>
  <task-list :taskOperatorType="taskOperatorType"></task-list>
</template>

<script>
import taskList from '@/view/haverenwu'
export default {
  name:"",
  components:{
    taskList
  },
  props:{},
  data(){
    return {
      taskOperatorType: 'checkOperator'
    }
  },
  watch:{},
  created(){},
  mounted(){},
  computed:{},
  methods:{}
}
</script>

<style lang="scss" scoped>
  
</style>